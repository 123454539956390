
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeUser,
    fetchAllUsers,
    selectAllUsers,
    deleteUser
} from "../../redux/slices/usersSlice";
import User from "./User";



function UsersListForChange() {
    const [selectedUser, setSelectedUser] = useState("Все");

    const allUsers = useSelector(selectAllUsers);



    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    // console.log(allUsers)
    const sortedUsers = allUsers.filter((user) => {
    const matchesTitle = user.role
        const matchesCategory = selectedUser === "Все" ||  user.role === selectedUser;
        return matchesTitle && matchesCategory;

    });


    return (
    <>
        <div className="change-user__search">
        <select 
            class="unauth1__text"
            name="role"
            id="role"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
        >
                        <option value="Все">Все</option>
                        <option value="ADMIN">ADMIN</option>
                        <option value="PROF">PROF</option>
                        <option value="NOPROF">NOPROF</option>
                        <option value="USER">USER</option>
        </select>
        </div>
        <div className="change-user__list">
        
        {sortedUsers.map((item) => (
                    <User data={item} key={item.id} />
                ))}
            </div>
            </>
    )
}
export default UsersListForChange;
