
import "./AdminPanel.scss";
import Loader from "../components/UI/Loader";
import UsersListForChange from "../components/AdminPanel/UsersListForChange";

import { useEffect, useState } from "react";
import MyDialog from "../components/UI/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";



function AdminPanel() {
    const [showStatistic, setShowStatistic] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

   
    return (
        
        <div className="home fc">
        {/* <div className="home__body fc__body"> */}
        <div className="wrapper">
            <div
                className="panel__loader"
                style={{ display: !isLoading ? "none" : "block" }}
            >
                <Loader />
                </div>
           
            <div className="panel" style={{ opacity: isLoading ? "0.5" : "1" }}>
            <div className="panel__title">Панель администратора</div>

      


            
                
  
            <div className="unauth1">
            

                <div className="panel__change-user">
                <h2>Управление пользователями</h2>
                    <UsersListForChange />
                </div>
           
                </div>

</div>

            </div>
        </div>
    );
}
export default AdminPanel;
