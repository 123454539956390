import { Link } from "react-router-dom";
import "./UnAuth.scss";
function UnAuth({ location }) {
    return (
        <div className="wrapper">
            <div className="unauth">
                <div className="unauth__title">
                    <h2>В данный момент вы не авторизованы</h2>
                    <br></br>
                </div>
                <div className="unauth__text">
                    <p>
                        {location} недоступен. Что бы получить доступ,
                        пожалуйста, авторизуйтесь
                    </p>
                </div>
                <br></br>
                {/* <button type="submit">Авторизоваться</button> */}
                <button type="submit"><a href="/auth/">Авторизоваться</a></button>
                {/* <Link to="/auth/">Авторизоваться</Link> */}
                <br></br>
            </div>
            <br></br>
        </div>
    );
}
export default UnAuth;
