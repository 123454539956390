// RezTest2.js
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupGroup, selectGroupGroup} from "../redux/slices/testSlice";
import { API_URL } from "../config";


import axios from "axios";

const SummGroupGroupMax = ({ item, id_user }) => {


  const [MaxGroup, setMaxGroup] = useState([]);
  const [GroupGroup, setGroupGroup] = useState([]);

  const id_test_typesumm_id_user = useMemo(() => ({
    id_test: item.id_test,
    typesumm: item.typesumm,
    id_user: id_user,
  }), [item.id_test, item.typesumm, id_user]);

  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios.get(`${API_URL}/test/getSummGroupGroup?id_test=${item.id_test}&id_user=${id_user}`)
      .then((response) => {


        const maxValue = response.data.data.reduce((max, group) => Math.max(max, parseFloat(group.summ_value_answer)), 0);
        const maxGroups = response.data.data.filter((group) => parseFloat(group.summ_value_answer) === maxValue);
        setMaxGroup(maxGroups);

        // console.log(maxValue);
        // console.log(maxGroups);


        const groupedData = response.data.data.reduce((acc, group) => {
          if (!acc[group.text_summ_group]) {
            acc[group.text_summ_group] = [];
          }
          acc[group.text_summ_group].push(group);
          return acc;
        }, {});
        setGroupGroup(Object.values(groupedData));
        
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [item.id_test, item.typesumm, id_user]);



  const titleTypeRez = JSON.parse(item.title_type_rez_);

  return (
    <div key={item.id_test}>


      {/* наибольшая схожесть */}

      {/* {console.log(MaxGroup)} */}
      <h2 style={{ fontSize: "19px" }}>{titleTypeRez.max_title}</h2>
      {MaxGroup
  .map((group, index) => (
    <div style={{ fontSize: "19px" }} key={index}>
      <b>{group.text_answer_group}</b>
      <div style={{ fontSize: "17px" }}>
      {group.text2_answer_group}
      <br />
    </div>
    </div>
  ))}




<br />
      <br />


      {/* вывод с группировкой */}


      <h2 style={{ fontSize: "19px" }}>{titleTypeRez.main_title}</h2>
      {GroupGroup.map((groupedItems, index) => (
        <div key={index}>
          <b style={{ fontSize: "19px" }}>{groupedItems[0].text_summ_group}</b>
          {groupedItems.map((group, groupIndex) => (
            <li style={{ fontSize: "19px" }}key={groupIndex}>
              {group.text_answer_group} ({group.summ_value_answer}/{group.max_summ})
            </li>
          ))}
        </div>
      ))}


<br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};



export default SummGroupGroupMax;
