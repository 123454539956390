

import React from "react";




import { navigate } from 'react-router-dom';

import {
  changeAnswer,
  saveUserTest,
} from "../redux/slices/testSlice";


import { connect } from "react-redux";







class ShowTest extends React.Component {


  state = this.props.test

 
  handleSaveUserTest = () => {


    // console.log(this.state.answeredQuestions)

    if (Object.values(this.state.answeredQuestions).some(item => item.id_answer_group === null || item.value_answer === null)) {
      alert('Ответьте на каждый вопрос') 
    } else {
      if (window.confirm('Вы уверены, что хотите завершить выполнения теста? Если вы захотите пройти тест заново то все результаты будут удалены.')) { 
        this.props.saveUserTest(
          {
            id_test: this.props.id_test_id_user_status.id_test,
            id_user: this.props.id_test_id_user_status.id_user,
            status: 'Пройден',
          }
        );
  
        window.location.href = '/cabinet';
  
      } 
    }


  
  //  else {
  //   // если нажата отмена открыть страницу /test
  // }
    // this.props.history.push('/cabinet');
    // this.props.dispatch(navigate('/cabinet'));
  }



 
  handleResponse = (id_question, value_answer , id_answer_group) => {
    
    
    const updatedQuestions = this.state.test.map((q) => 
    //   q.buttons.map((button) => (
    //     button.id_question === id_question && button.value_answer === value_answer && button.id_answer_group === id_answer_group ? { ...button, selected: id_question } : button
    //     button.id_question === id_question && button.value_answer === value_answer && button.id_answer_group === id_answer_group ? { ...button, selected: id_question } : button
    //   )
    //       )
    // )
      (q.id_question === id_question ? { ...q, selected: true} : q)
    
    
    );
    
    // console.log(updatedQuestions)
    this.setState({ test: updatedQuestions });

    // const updatedQuestions = { ...this.state.buttons, [id_question]: {id_answer_group, value_answer, } };
    const updatedAnswers = { ...this.state.answeredQuestions, [id_question]: {
      id_answer_group,
      value_answer,
    }};


    const dataAnswer = {
      id_test: this.props.id_test_id_user_status.id_test, 
      id_user: this.props.id_test_id_user_status.id_user,
      id_question: id_question,
      id_answer_group: id_answer_group,
      value_answer: value_answer,
    }


    this.props.changeAnswer(dataAnswer);


   // this.props.history.push('/cabinet');
  // this.props.dispatch(navigate('/cabinet'));

    this.setState({ answeredQuestions: updatedAnswers });


    // console.log(this.state);
    // console.log(`Question ${id_question}: ${value_answer}`);
  };




  handleSetChange = (index) => {
    // console.log("index", index)
    this.setState({
      displayedSetIndex: index,
    });
  };
  handleQuestionClick = (questionId) => {
    // console.log(questionId);
    const setIndex = Math.floor((questionId - 1) / 5);
    this.setState({
      displayedSetIndex: setIndex,
    });
    // console.log(setIndex);
  };

  render() {

    
    const { test, displayedSetIndex, answeredQuestions, testinfo } = this.state;
    let answer = answeredQuestions
    const testToShow = test.slice(displayedSetIndex * 5, displayedSetIndex * 5 + 5);
    const buttonStyle = {
      fontSize: "1em",
      width: "35px",
      height: "35px",
      justifyContent: "center",
      alignItems: "center",
    };

    const selectedButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#63C97A",
    };



    // console.log("=====", this.state);
    return (
      <div className="rounded-border1" style={{ /*fontFamily: "Magistral"*/ float: "left" }}>
        {testinfo.map((info) =>(
          <>
          <h2 style={{ fontSize: "28px" }}>{info.name}</h2>

          <QuestionMenu test={test} handleQuestionClick={this.handleQuestionClick} />

          <h2 style={{ fontSize: "14px", paddingLeft: "0px" }}>
          {info.author}
        </h2>
        <br />
        <h2 style={{ fontSize: "16px", paddingRight: "400px" }}>
          {info.instruction}
        </h2>
          </>


        ))}
       

        <br />

        <ul>
          {testToShow.map((q) => (
            <div style={{ float: "left" }} className="rounded-border-question" key={q.id}>
              <h2></h2>
              <div style={{ float: "left" }} className="rounded-border-question-text" key={q.id}>{q.text_question}</div>
                <div style={{ float: "right" }} className="rounded-border-answer">
    
                
                  {q.buttons.map((button) => (
                    <>
                    {button.text_answer !=="" ? (<><br/></>): " "}
                   
                    <div style={{ float: "left" }} className="rounded-border-answer-text " >
                    
        
                    <button 
                      className="menu-button"
                      key={button.id}

                      style={
                        answer[button.id_question] &&
                        answer[button.id_question].value_answer === button.value_answer &&
                        answer[button.id_question].id_answer_group === button.id_answer_group
                        ? selectedButtonStyle 
                        : buttonStyle
                      }
                      onClick={() => this.handleResponse(q.id_question, button.value_answer, button.id_answer_group)}
                    >


                      {button.response}
                    </button>
                    &nbsp;{button.text_answer} 
                    

                    
                    </div>
                    
                    </>
                  ))}
                </div>
             </div>
          ))}
        </ul>
        <div style={{ marginTop: "20px" }}>
       

        {displayedSetIndex === Math.ceil(test.length / 5 - 1)  && (
            <button
              className="rounded-border-next2"
              onClick={() => this.handleSaveUserTest()}
            >
              Принять
            </button>
           )}
        {displayedSetIndex < Math.ceil(test.length / 5) - 1 && (
            <button
              className="rounded-border-next"
              onClick={() => this.handleSetChange(displayedSetIndex + 1)}
            >
              Следующая страница
            </button>
           )}
          {displayedSetIndex > 0 && (
            <button
              className="rounded-border-next"
              onClick={() => this.handleSetChange(displayedSetIndex - 1)}
            >
              Предыдущая страница
            </button>
          )}
      
        </div>
      </div>
      
    );

    
  }


 
  
}

const mapDispatchToProps = {
  saveUserTest,
  changeAnswer,
};


export default connect(null, mapDispatchToProps)(ShowTest);




class QuestionMenu extends React.Component {


  componentDidMount() {
    // this.handleQuestionClick();
  }


  render() {
    const { test, handleQuestionClick } = this.props;
    const buttonStyle = {
      fontSize: "1em",
      width: "35px",
      height: "35px",
      justifyContent: "center",
      alignItems: "center",
    };

    const selectedButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#FFD30A",
    };

    return (
      <div className="rounded-border-menu" style={{ float: "right"}}>
        <ul className="question-column">
          {/* {console.log(test)} */}
          {test.reduce((columns, q, index) => {
            const columnIndex = Math.floor(index / 5);
            
            if (!columns[columnIndex]) {
              columns[columnIndex] = []; 
            }
    
            columns[columnIndex].push(
              <li key={q.id_question}>
                <button
                  className="menu-button"
                  style={
                    q.selected ? selectedButtonStyle : buttonStyle
                  }


                 
                  onClick={() => handleQuestionClick(q.id_question)}
                >
                  {q.id_question}
                </button>
              </li>
            );
    
            return columns;
          }, []).map((column, index) => (
            <div key={index} style={{ float: 'left' }}>
              <ul className="question-row">{column}</ul>
            </div>
          ))
          }
        </ul>
      </div>
    );
  }




}




