import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setError } from "./errorSlice";
import { API_URL } from "../../config";



import { setSuccess } from "./successSlice";


const initialState = {
    allUsers: [],
    singleUser: {},
    categories: [],
    allUsersLength: 0,
    advicedLoading: false,
    allUsersLoading: false,
    singleUserLoading: false,
};

export const fetchAllUsers = createAsyncThunk(
    "users/getAllUsers",
    async (thunkAPI) => {
        try {

            const res = await axios.get(`${API_URL}/user/getAllUsers`);
            return res.data;
        } catch (error) {

            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);


export const deleteUser = createAsyncThunk(
    "users/deleteUser",
    async (userId, thunkAPI) => {
        try {
            const res = await axios.delete(`${API_URL}/user/deleteUser`, {
                params: {
                    userId,
                },
            });
            console.log(userId)
            if (res.data.message === "Deleted success") {
                thunkAPI.dispatch(fetchAllUsers());
                thunkAPI.dispatch(setSuccess("Пользователь удален"));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);


export const changeUser = createAsyncThunk(
    "users/changeUser",
    async (changedUser, thunkAPI) => {
        try {
            const res = await axios.put(`${API_URL}/user/changeUser`, {
                params: {
                    changedUser,
                },
            });
            if (res.data.message === "Changes success") {
                thunkAPI.dispatch(fetchAllUsers());
                thunkAPI.dispatch(setSuccess("Пользователь изменен"));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);




export const createUser = createAsyncThunk(
    "users/createUser",
    async (createdUser, thunkAPI) => {
        try {
            const res = await axios.post(`${API_URL}/user/createUser`, {
                params: {
                    createdUser,
                },
            });

            if (res.data.message === "Create success") {
                // thunkAPI.dispatch(fetchAllUsers());
                thunkAPI.dispatch(setSuccess("Заявка отправлена"));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);



export const fetchAdvicedUsers = createAsyncThunk(
    "users/getAdvicedUsers",
    async (thunkAPI) => {
        try {
            const res = await axios.get(
                `${API_URL}/user/getAdvicedUsers`
            );
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);

export const fetchSingleUser = createAsyncThunk(
    "users/getSingleUser",
    async (userId, thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/user/getSingleUser`, {
                params: { userId },
            });
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);

export const fetchFavoriteUsers = createAsyncThunk(
    "users/getFavoriteUsers",
    async (thunkAPI) => {
        try {
            const res = await axios.get(
                `${API_URL}/user/getFavoriteUsers`
            );
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        const handleApiCall = (state, action) => {
            state.singleUser = {};
            const typeOfFetchingData = action.type.split("/")[1];
            switch (typeOfFetchingData) {
                case "getAdvicedUsers":
                    state.advicedLoading = true;
                    break;
                case "getAllUsers":
                    state.allUsersLoading = true;
                    break;
                case "getSingleUser":
                    state.singleUserLoading = true;
                    break;
                default:
                    break;
            }
        };

        const handleApiSuccess = (state, action) => {
            if (!action.payload) {
                return initialState;
            }
            const typeOfFetchingData = action.type.split("/")[1];
            // console.log(typeOfFetchingData);
            switch (typeOfFetchingData) {
                case "getAllUsers":
                    state.allUsers = action.payload.data;
                    state.allUsersLength = action.payload.data.length;
                    state.allUsersLoading = false;
                    // state.categories = action.payload.categories;
                    break;
                case "getAdvicedUsers":
                    state.isAdvice = action.payload.data;
                    state.advicedLoading = false;
                    break;
                case "getSingleUser":
                    state.singleUser = action.payload.data[0];
                    state.singleUserLoading = false;
                    break;
                default:
                    break;
            }
        };

        const handleApiError = (state) => {
            state.advicedLoading = false;
            state.favoriteLoading = false;
            state.singleUserLoading = false;
        };

        

        builder
            .addCase(deleteUser.pending, handleApiCall)
            .addCase(deleteUser.fulfilled, handleApiSuccess)
            .addCase(deleteUser.rejected, handleApiError)
            .addCase(createUser.pending, handleApiCall)
            .addCase(createUser.fulfilled, handleApiSuccess)
            .addCase(createUser.rejected, handleApiError)
            .addCase(fetchAllUsers.pending, handleApiCall)
            .addCase(fetchAllUsers.fulfilled, handleApiSuccess)
            .addCase(fetchAllUsers.rejected, handleApiError)
            .addCase(fetchAdvicedUsers.pending, handleApiCall)
            .addCase(fetchAdvicedUsers.fulfilled, handleApiSuccess)
            .addCase(fetchAdvicedUsers.rejected, handleApiError)
            .addCase(fetchSingleUser.pending, handleApiCall)
            .addCase(fetchSingleUser.fulfilled, handleApiSuccess)
            .addCase(fetchSingleUser.rejected, handleApiError)
    },
});

export const selectAdvicedLoading = (state) => state.users.advicedLoading;
export const selectIsTrends = (state) => state.users.isTrends;
export const selectCategories = (state) => state.users.categories;
export const selectAllUsers = (state) => state.users.allUsers;
export const selectAllUsersLoading = (state) => state.users.allUsersLoading;
export const selectAllUsersLength = (state) => state.users.allUsersLength;
export const selectIsAdvice = (state) => state.users.isAdvice;
export const selectSingleUser = (state) => state.users.singleUser;
export const selectSingleUserLoading = (state) => state.users.singleUserLoading;
export default usersSlice.reducer;
