import React from "react";
import { navigate } from 'react-router-dom';

import {
  changeAnswer,
  saveUserTest,
} from "../redux/slices/testSlice";
import { connect } from "react-redux";



class ShowTest2 extends React.Component {


  state = this.props.test

 
  handleSaveUserTest = () => {


    

    // if (Object.values(answeredQuestions).some(group =>
    //   group.some(item => item.id_answer_group === null || item.value_answer === null)
    // )) {
    // console.log(this.state.answeredQuestions)

        
  


    if (Object.values(this.state.answeredQuestions).some(group => {
      const uniqueValue = new Set(group.map(item => item.value_answer));
      if (group.some(item => item.id_answer_group === null || item.value_answer === null)) {
        alert('Ответьте на каждый вопрос'); 
        return group.some(item => item.id_answer_group === null || item.value_answer === null)
      }
      else if (uniqueValue.size !== group.length) {
        alert('Ответы групп не должны повторяться!'); 
        return uniqueValue.size !== group.length
      }
    })) {}
    else {
      if (window.confirm('Вы уверены, что хотите завершить выполнения теста? Если вы захотите пройти тест заново то все результаты будут удалены.')) { 
        this.props.saveUserTest(
          {
            id_test: this.props.id_test_id_user_status.id_test,
            id_user: this.props.id_test_id_user_status.id_user,
            status: 'Пройден',
          }
        );
  
        window.location.href = '/cabinet';
  
      }
    }     

  }



  handleResponse = (id_question, value_answer, id_answer_group, num_) => {
    
    console.log(this.props.test)

    
    const updatedQuestions = this.state.test.map((q) =>
      q.id_question === id_question ? { ...q, selected: true } : q
    );
  
   
    this.setState({ test: updatedQuestions });
    



    const updatedAnswers = {
      ...this.state.answeredQuestions,
      [id_question]: this.state.answeredQuestions[id_question]
        ? this.state.answeredQuestions[id_question].map((item) => {
            if (item.num_ === num_) {
              return {
                id_answer_group,
                value_answer,
                num_,
              };
            }
            return item;
          })
        : [
            {
              id_answer_group,
              value_answer,
              num_,
            },
          ],
    };
  

    const dataAnswer = {
      id_test: this.props.id_test_id_user_status.id_test,
      id_user: this.props.id_test_id_user_status.id_user,
      id_question: id_question,
      id_answer_group: id_answer_group,
      value_answer: value_answer,
      num_: num_,
    };
  
    this.props.changeAnswer(dataAnswer);
    this.setState({ answeredQuestions: updatedAnswers });
  };
  
  

  

  // handleResponse = (id_question, value_answer, id_answer_group, num_) => {
  //   const updatedQuestions = this.state.test.map((q) =>
  //     q.id_question === id_question ? { ...q, selected: true } : q
  //   );
  
  //   this.setState({ test: updatedQuestions });
  
  //   const updatedAnswers = {
  //     ...this.state.answeredQuestions,
  //     [id_question]: this.state.answeredQuestions[id_question]
  //       ? this.state.answeredQuestions[id_question].map((item) => {
  //           if (item.num_ === num_) {
  //             return {
  //               id_answer_group,
  //               value_answer,
  //               num_
  //             };
  //           }
  //           return item;
  //         })
  //       : [
  //           {
  //             id_answer_group,
  //             value_answer,
  //             num_
  //           }
  //         ]
  //   };
  

    
    
  
  //   const dataAnswer = {
  //     id_test: this.props.id_test_id_user_status.id_test,
  //     id_user: this.props.id_test_id_user_status.id_user,
  //     id_question: id_question,
  //     id_answer_group: id_answer_group,
  //     value_answer: value_answer,
  //     num_: num_
  //   };
  
  //   this.props.changeAnswer(dataAnswer);
  //   this.setState({ answeredQuestions: updatedAnswers });
  // };
   




  // handleResponse = (id_question, value_answer , id_answer_group, num_) => {
  
    
  //   const updatedQuestions = this.state.test.map((q) => 
  //     (q.id_question === id_question ? { ...q, selected: true} : q)
  //   );

    
  //   this.setState({ test: updatedQuestions });

  //   const updatedAnswers = {
  //     ...this.state.answeredQuestions,
  //     [id_question]: this.state.answeredQuestions[id_question]
  //       ? this.state.answeredQuestions[id_question].map(item => {
  //           if (item.num_ === num_) {
  //             return {
  //               id_answer_group,
  //               value_answer,
  //               num_
  //             };
  //           }
  //           return item;
  //         })
  //       : [
  //           {
  //             id_answer_group,
  //             value_answer,
  //             num_
  //           }
  //         ]
  //   };
    
  //   const dataAnswer = {
  //     id_test: this.props.id_test_id_user_status.id_test, 
  //     id_user: this.props.id_test_id_user_status.id_user,
  //     id_question: id_question,
  //     id_answer_group: id_answer_group,
  //     value_answer: value_answer,
  //     num_: num_,
  //   }

  //   this.props.changeAnswer(dataAnswer);
  //   this.setState({ answeredQuestions: updatedAnswers });
  // };


  handleSetChange = (index) => {
    this.setState({
      displayedSetIndex: index,
    });
  };
  
  handleQuestionClick = (questionId) => {
    const setIndex = Math.floor((questionId - 1) / 5);
    this.setState({
      displayedSetIndex: setIndex,
    });
    // console.log(setIndex);
  };

  render() {
    
    
    const { test, displayedSetIndex, answeredQuestions, testinfo } = this.state;

    // console.log(answeredQuestions)
    let answer = answeredQuestions
    const testToShow = test.slice(displayedSetIndex * 5, displayedSetIndex * 5 + 5);
    const buttonStyle = {
      fontSize: "1em",
      width: "35px",
      height: "35px",
      justifyContent: "center",
      alignItems: "center",
    };

    const selectedButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#63C97A",
    };


  
    
    return (


      

      <div className="rounded-border1" style={{ /*fontFamily: "Magistral"*/ float: "left" }}>
        {testinfo.map((info) =>(
          <>
          <h2 style={{ fontSize: "28px" }}>{info.name}</h2>

          <QuestionMenu test={test} handleQuestionClick={this.handleQuestionClick} />

          <h2 style={{ fontSize: "14px", paddingLeft: "0px" }}>
          {info.author}
        </h2>
        <br />
        <h2 style={{ fontSize: "16px", paddingRight: "400px" }}>
          {info.instruction}
        </h2>
          </>


        ))}
       

        <br />

        <ul>
          {/* ///--------------------- */}
          
  
        
        {testToShow.map((q) => (
            <div style={{ float: "left" }} className="rounded-border-question" key={q.id}>
              {/* <h2>{console.log("=====", q)}</h2>
              <h2>{console.log("=====", answer)}</h2> */}
              <div style={{ float: "left" }} className="rounded-border-question-text" key={q.id}>{q.text_question}</div>
                <div style={{ float: "right" }} className="rounded-border-answer">
    
                
                  {q.buttons.map((button, index) => (


                    <>
                    
                    {button.text_answer !=="" ? (<><br/></>): " "}
                    
                    <div style={{ float: "left" }} className="rounded-border-answer-text">
                    {[1, 2, 3, 4, 5].map((value) => (
                      
                      <>
                      {/* {console.log("-----", answer,  value)} */}
                      <button
                        // key={`${q.id_question}-${value}`}
                        className="menu-button"
                        style={
                          // answer[q.id_question].value_answer &&

                          //answer[q.id_question].id_answer_group === button.id_answer_group &&
                          answer[q.id_question][index].num_ === index + 1 &&
                          answer[q.id_question][index].value_answer === value

                            ? selectedButtonStyle
                            : buttonStyle
                        }
                        onClick={() => this.handleResponse(q.id_question, value, button.id_answer_group, index + 1)}
                      >
                        {value}
                      </button>
                      </>
                  
                    ))}
                    &nbsp;{button.text_answer}
                  </div>
                    </>
                  ))}
                </div>
             </div>
          ))}


                  {/* ///--------------------- */}

        </ul>
        <div style={{ marginTop: "20px" }}>
       

        {displayedSetIndex === Math.ceil(test.length / 5 - 1)  && (
            <button
              className="rounded-border-next2"
              onClick={() => this.handleSaveUserTest()}
            >
              Принять
            </button>
           )}
        {displayedSetIndex < Math.ceil(test.length / 5) - 1 && (
            <button
              className="rounded-border-next"
              onClick={() => this.handleSetChange(displayedSetIndex + 1)}
            >
              Следующая страница
            </button>
           )}
          {displayedSetIndex > 0 && (
            <button
              className="rounded-border-next"
              onClick={() => this.handleSetChange(displayedSetIndex - 1)}
            >
              Предыдущая страница
            </button>
          )}
      
        </div>
      </div>
      
    );

    
  }


 
  
}

const mapDispatchToProps = {
  saveUserTest,
  changeAnswer,
};


export default connect(null, mapDispatchToProps)(ShowTest2);




class QuestionMenu extends React.Component {


  componentDidMount() {
    // this.handleQuestionClick();
  }


  render() {
    const { test, handleQuestionClick, answeredQuestions } = this.props;
    const buttonStyle = {
      fontSize: "1em",
      width: "35px",
      height: "35px",
      justifyContent: "center",
      alignItems: "center",
    };

    const selectedButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#FFD30A",
    };

    return (
      <div className="rounded-border-menu" style={{ float: "right"}}>
        <ul className="question-column">
        {console.log(test)}
        {console.log(answeredQuestions)}
          {test.reduce((columns, q, index) => {
            const columnIndex = Math.floor(index / 5);
            
            if (!columns[columnIndex]) {
              columns[columnIndex] = []; 
            }
    
            columns[columnIndex].push(
              <li key={q.id_question}>
                <button
                  className="menu-button"
                  style={
                    q.selected ? selectedButtonStyle : buttonStyle
                  }


                 
                  onClick={() => handleQuestionClick(q.id_question)}
                >
                  {q.id_question}
                </button>
              </li>
            );
    
            return columns;
          }, []).map((column, index) => (
            <div key={index} style={{ float: 'left' }}>
              <ul className="question-row">{column}</ul>
            </div>
          ))
          }
        </ul>
      </div>
    );
  }




}




