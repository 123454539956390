import { useState } from "react";
import "./About.scss";
import Loader from "../components/UI/Loader";
function About() {
    const [imgIsLoading, setImgIsLoading] = useState(false);
    const image = new Image();
    image.onload = () => {
        setImgIsLoading(true);
    };
    image.onerror = () => {
        console.log("Произошла ошибка при загрузке изображения.");
    };
    image.src =
        "https://eus-www.sway-cdn.com/s/9WdfXuGcIa7wG2VR/images/oTe5OYoPwIPm4A?quality=1280&allowAnimation=true";
    return (
        <div className="home fc">
       
        <div className="wrapper">
           
        </div>
        </div>
    );
}
export default About;
