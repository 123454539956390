import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setError } from "./errorSlice";
import { API_URL } from "../../config";
import { setSuccess } from "./successSlice";




const initialState = {
    getBlock: [],
    getTestBlock: [],
    getUserTest: [],
    getUserProf: [],
    getUserBlock: [],
    getTest: [],
    getTypeRez_: [],
    getGroupGroup: [],
    getBlockLength: 0,
    getBlockLoading: false,
    getTestLoading: false,
    getGroupGroupLoading: true,
};




export const getGroupGroup = createAsyncThunk(
    "test/getGroupGroup",
    async (id_test_typesumm_id_user, thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/test/getGroupGroup`, {
                params: {
                    id_test: id_test_typesumm_id_user.id_test,
                    typesumm: id_test_typesumm_id_user.typesumm,
                    id_user: id_test_typesumm_id_user.id_user,
                },
            });
            // if (res.data.message === "Changes success") {
            //     thunkAPI.dispatch(setSuccess("Ответ принят"));
            // }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);



export const saveDocumentToServer = createAsyncThunk(
    "test/saveDocumentToServer",
    async (saveDocument, thunkAPI) => {
       /// console.log("3===",saveDocument.saveDocuments);

        try {
            const res = await axios.post(`${API_URL}/test/saveDocumentToServer`, {
                params: {
                    saveDocuments:saveDocument.saveDocuments,
                    saveDocumentemail:saveDocument.saveDocumentemail,
                    bloknam:saveDocument.bloknam,
                    
                },
            });
            // if (res.data.message === "Create success") {
            //     thunkAPI.dispatch(setSuccess("Прохождение начато"));
            // }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);





// export const getTypeRez_= createAsyncThunk(
//     "test/getTypeRez_",
//     async (id_block, thunkAPI) => {
//         try {
//             const res = await axios.get(`${API_URL}/test/getTypeRez_`, {
//                 params: {
//                     id_block,

//                 },
//             });
//             // if (res.data.message === "Changes success") {
//             //     thunkAPI.dispatch(setSuccess("Ответ принят"));
//             // }
//             return res.data;
//         } catch (error) {
//             thunkAPI.dispatch(setError(error.response.data.message));
//             throw error;
//         }
//     }
// );


export const getTypeRez_ = createAsyncThunk(
    "test/getTypeRez_",
    async (id_block_, thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/test/getTypeRez_`, {
                params: {
                    id_block: id_block_.id_block,
                    id_user: id_block_.id_user,
                },
            });
        
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);





// export const getTypeRez_= createAsyncThunk(
//     "test/getTypeRez_",
//     async (id_block, thunkAPI) => {
//         try {
//             const res = await axios.get(`${API_URL}/test/getTypeRez_`, {
//                 params: {
//                     id_block,
//                 },
//             });
//             // if (res.data.message === "Changes success") {
//             //     thunkAPI.dispatch(setSuccess("Ответ принят"));
//             // }
//             return res.data;
//         } catch (error) {
//             thunkAPI.dispatch(setError(error.response.data.message));
//             throw error;
//         }
//     }
// );





export const changeAnswer = createAsyncThunk(
    "test/changeAnswer",
    async (dataAnswer, thunkAPI) => {
        try {
            const res = await axios.put(`${API_URL}/test/changeAnswer`, {
                params: {
                    dataAnswer,
                },
            });
            // if (res.data.message === "Changes success") {
            //     thunkAPI.dispatch(setSuccess("Ответ принят"));
            // }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);


export const saveUserTest = createAsyncThunk(
    "test/saveUserTest",
    async (id_test_id_user_status, thunkAPI) => {
       
        try {
            // console.log(id_test_id_user_status)
            const res = await axios.put(`${API_URL}/test/saveUserTest`, {
                params: {
                    id_test_id_user_status,
                },
            });
            if (res.data.message === "Changes success") {
                thunkAPI.dispatch(setSuccess("Ответ записан"));
            }
            else {
                thunkAPI.dispatch(setSuccess(""));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);






export const AnswerUser = createAsyncThunk(
    "test/AnswerUser",
    async (id_test_id_user, thunkAPI) => {
        // console.log("id_test_id_user", id_test_id_user)
        try {


            const res = await axios.post(`${API_URL}/test/AnswerUser`, {
                params: {
                    id_test_id_user,
                },
            });

                

            if (res.data.message === "Create success") {
                thunkAPI.dispatch(setSuccess("Прохождение начато"));
            }
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);





export const getTest = createAsyncThunk(
    "test/getTest",
    async (id_test_id_user, thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/test/getTest`, {
                params: {
                    id_test: id_test_id_user.id_test,
                    id_user: id_test_id_user.id_user,
                },
            });
        
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);


export const getUserBlock = createAsyncThunk(
    "test/getUserBlock",
    async (id_user, thunkAPI) => {
        try {


            const res = await axios.get(`${API_URL}/test/getUserBlock`, {
                params: {
                    id_user,
                },
            });
        
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);





export const getUserProf = createAsyncThunk(
    "test/getUserProf",
    async (id_prof, thunkAPI) => {
        try {


            const res = await axios.get(`${API_URL}/test/getUserProf`, {
                params: {
                    id_prof,
                },
            });
        
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);






export const getBlock = createAsyncThunk(
    "test/getBlock",
    async (id_user, thunkAPI) => {
        try {


            const res = await axios.get(`${API_URL}/test/getBlock`, {
                params: {
                    id_user,
                },
            });
            
           
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);



export const getTestBlock = createAsyncThunk(
    "test/getTestBlock",
    async (id_block, thunkAPI) => {
        try {


            const res = await axios.get(`${API_URL}/test/getTestBlock`, {
                params: {
                    id_block,
                },
            });
            
           
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);


export const getUserTest = createAsyncThunk(
    "test/getUserTest",
    async (id_user, thunkAPI) => {
        try {


            const res = await axios.get(`${API_URL}/test/getUserTest`, {
                params: {
                    id_user,
                },
            });
            
           
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);





const testSlice = createSlice({
    name: "test",
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {

        const handleApiCall = (state, action) => {
            const typeOfFetchingData = action.type.split("/")[1];
            switch (typeOfFetchingData) {
                case "getBlock":
                    state.getBlockLoading = true;
                    break;
                case "getTestBlock":
                    state.getTestBlockLoading = true;
                    break;
                case "getUserTest":
                    state.getUserTestLoading = true;
                    break;
                case "getUserProf":
                    state.getUserProfLoading = true;
                    break;
                case "getUserBlock":
                    state.getUserBlockLoading = true;
                    break;
                case "getTest":
                    state.getTestLoading = true;
                    break;
                case "getTypeRez_":
                    state.getTypeRez_Loading = true;
                    break;
                case "getGroupGroupt":
                    state.getGroupGroupLoading = true;
                    break;
                   
                default:
                    break;
            }
        };


        
        



        const handleApiSuccess = (state, action) => {
            if (!action.payload) {
                return initialState;
            }
            const typeOfFetchingData = action.type.split("/")[1];
            switch (typeOfFetchingData) {
                case "getBlock":
                    state.getBlock = action.payload.data;
                    state.getBlockLength = action.payload.data.length;
                    state.getBlockLoading = false;
                    break;
                case "getTestBlock":
                    state.getTestBlock = action.payload.data;
                    state.getTestBlockLength = action.payload.data.length;
                    state.getTestBlockLoading = false;
                    break;
                case "getUserTest":
                    state.getUserTest = action.payload.data;
                    state.getUserTestLength = action.payload.data.length;
                    state.getUserTestLoading = false;
                    break;
                case "getUserProf":
                    state.getUserProf = action.payload.data;
                    state.getUserProfLength = action.payload.data.length;
                    state.getUserProfLoading = false;
                    break;
                case "getUserBlock":
                    state.getUserBlock= action.payload.data;
                    state.getUserBlockLength = action.payload.data.length;
                    state.getUserBlockLoading = false;
                    break;
                case "getTest":
                    state.getTest= action.payload.data;
                    state.getTestLength = action.payload.data.length;
                    state.getTestLoading = false;
                    // console.log("state.getTestLoading = false")
                    break;
                case "getTypeRez_":
                    state.getTypeRez_ = action.payload.data;
                    state.getTypeRez_Length = action.payload.data.length;
                    state.getTypeRez_Loading = false;
                    break;
                
                case "getGroupGroup":
                    state.getGroupGroup = action.payload.data;
                    state.getGroupGroupLength = action.payload.data.length;
                    state.getGroupGroupLoading = false;
                    break;


                    
                default:
                    break;
            }
        };

        


        const handleApiError = (state) => {
            state.BlockLoading = false;
            state.getTestLoading = false;
        };

        

        builder
        .addCase(getBlock.pending, handleApiCall)
        .addCase(getBlock.fulfilled, handleApiSuccess)
        .addCase(getBlock.rejected, handleApiError)
        
        .addCase(getTestBlock.pending, handleApiCall)
        .addCase(getTestBlock.fulfilled, handleApiSuccess)
        .addCase(getTestBlock.rejected, handleApiError)
        
        .addCase(getUserTest.pending, handleApiCall)
        .addCase(getUserTest.fulfilled, handleApiSuccess)
        .addCase(getUserTest.rejected, handleApiError)


        .addCase(getUserProf.pending, handleApiCall)
        .addCase(getUserProf.fulfilled, handleApiSuccess)
        .addCase(getUserProf.rejected, handleApiError)

        .addCase(getUserBlock.pending, handleApiCall)
        .addCase(getUserBlock.fulfilled, handleApiSuccess)
        .addCase(getUserBlock.rejected, handleApiError)

        .addCase(getTest.pending, handleApiCall)
        .addCase(getTest.fulfilled, handleApiSuccess)
        .addCase(getTest.rejected, handleApiError)

        .addCase(AnswerUser.pending, handleApiCall)
        .addCase(AnswerUser.fulfilled, handleApiSuccess)
        .addCase(AnswerUser.rejected, handleApiError)


        .addCase(changeAnswer.pending, handleApiCall)
        .addCase(changeAnswer.fulfilled, handleApiSuccess)
        .addCase(changeAnswer.rejected, handleApiError)


        .addCase(saveUserTest.pending, handleApiCall)
        .addCase(saveUserTest.fulfilled, handleApiSuccess)
        .addCase(saveUserTest.rejected, handleApiError)


        .addCase(getTypeRez_.pending, handleApiCall)
        .addCase(getTypeRez_.fulfilled, handleApiSuccess)
        .addCase(getTypeRez_.rejected, handleApiError)


        .addCase(getGroupGroup.pending, handleApiCall)
        .addCase(getGroupGroup.fulfilled, handleApiSuccess)
        .addCase(getGroupGroup.rejected, handleApiError)


        .addCase(saveDocumentToServer.pending, handleApiCall)
        .addCase(saveDocumentToServer.fulfilled, handleApiSuccess)
        .addCase(saveDocumentToServer.rejected, handleApiError)

       
        
    },
});



export const selectBlock = (state) => state.test.getBlock;
export const selectTestBlock = (state) => state.test.getTestBlock;
export const selectUserTest = (state) => state.test.getUserTest;
export const selectUserProf = (state) => state.test.getUserProf;
export const selectUserBlock = (state) => state.test.getUserBlock;




export const selectTest = (state) => state.test.getTest;
export const selectTestIsLoading = (state) => state.test.getTestLoading;

export const selectTypeRez_ = (state) => state.test.getTypeRez_;

export const selectGroupGroup = (state) => state.test.getGroupGroup;

export const selectOutputTestRezLoading = (state) => state.test.getOutputTestRezLoading;



export default testSlice.reducer;
