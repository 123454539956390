import { useSelector } from "react-redux";
import { useState } from "react";
import { selectIsAuth } from "../redux/slices/userSlice";
import UnAuth from "../components/UI/UnAuth";
import "./Auth.scss";
import "./Test.scss";


import ShowTest from "./ShowTest";
import ShowTest2 from "./ShowTest2";

import {
    selectUserId,
    selectIsUser
   
} from "../redux/slices/userSlice";


import { useDispatch } from "react-redux";



import {
    selectTest,
    getTest,

    selectTestIsLoading,

    selectUserTest,
    getUserTest,

    changeAnswer,
  saveUserTest,
    
  } from "../redux/slices/testSlice";



  import { useEffect } from "react";





  import { useParams } from 'react-router-dom';




//   const TestComponent =



const Test = () => {


   
    

    const isAuth = useSelector(selectIsAuth);
    const isUser = useSelector(selectIsUser);

    // const Test = useSelector(selectTest);



    
   
    const { id_test } = useParams();

    const Test = useSelector(selectTest);
    // const TestIsLoading = useSelector(selectTestIsLoading);
    
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();



    const userId = useSelector(selectUserId);



    // const id_type = useSelector(selectType);



    const id_test_id_user_status = {
        id_test: id_test,
        id_user: userId,
        status: '',
      };



    useEffect(() => {

        // console.log("const userId", userId, id_test)
        if (userId) {

                 dispatch(getTest(id_test_id_user_status))
        .then(() => setIsLoading(false));
        }
        
    }, [dispatch, id_test, userId]);




    const test_type = Test?.testinfo;

    if (test_type && test_type.length > 0) {
      if (test_type[0].id_type === 2) {
        // Если id_type равен 2, отрендерить ShowTest2 компонент
        return (
          <>
            <div className="fc fc-auth">
              {isAuth && isUser ? (
                <>
                  <div className="wrapper">
                  {!isLoading && <ShowTest2 test={Test} id_test_id_user_status={id_test_id_user_status} />}
                  </div>
                </>
              ) : (
                <UnAuth location="Личный кабинет" />
              )}
            </div>
          </>
        );
      } else {
        // Если id_type не равен 2, отрендерить ShowTest компонент
        return (
          <>
            <div className="fc fc-auth">
              {isAuth && isUser ? (
                <>
                  <div className="wrapper">
                    {!isLoading && <ShowTest test={Test} id_test_id_user_status={id_test_id_user_status} />}
                  </div>
                </>
              ) : (
                <UnAuth location="Личный кабинет" />
              )}
            </div>
          </>
        );
      }
    } else {
      console.log('Свойство testinfo не найдено или пустое');
      return null;
    }
    



//     const test_type = Test?.testinfo
//     if (test_type) {
//     console.log(test_type[0].id_type)
// } else {
// console.log('Свойство testinfo не найдено или пустое')
// }





// return (
//     <>

//     <div className="fc fc-auth">
//         {isAuth && isUser ? (
//             <>
//                 <div className="wrapper">     
//                 {/* {TestIsLoading ? (<ShowTest test={Test} TestIsLoading = {TestIsLoading}/>) : ""} */}
//                 { !isLoading && <ShowTest test={Test} id_test_id_user_status = {id_test_id_user_status}/>}
//                 </div>
//             </>
//         ) : (
//             <UnAuth location="Личный кабинет" />
//         )}
//         </div>
//     </>
// );


  
    
}
export default Test;
