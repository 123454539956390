import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { changeUser } from "../../redux/slices/usersSlice";
import "./MyCabinet.scss";



// import setVisible from "./UsersListForChange";


function ChangeUser({ setVisible, user }) {
    // alert(user.id)

    
    const id = user.data.id;
    // const last_name = user.last_name;
    
    // key = {user.id}

    const [last_name, setlast_name] = useState(user.data.last_name);
    const [first_name, setfirst_name] = useState(user.data.first_name);
    const [nickname, setnickname] = useState(user.data.nickname);
    const [email, setemail] = useState(user.data.email);
    const [role, setrole] = useState(user.data.role);

    

    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();
        // if (category === "Выберите роль") {
        //     alert("Выберите роль");
        // } else {
        // }
        
        const changedUser = {
            id,
            last_name,
            first_name,
            nickname,
            email,
            role
        };

        dispatch(changeUser(changedUser));
        setVisible(false);
        // setlast_name("");
        // setfirst_name("");
        // setnickname("");
        // setemail("");
        // setrole("");
        


    // const [categories, setCategories] = useState([]);
    // const cats = useSelector(selectCategories);


    // const [description, setDescription] = useState(user.description);
    // const [price, setPrice] = useState(
    //     Number(user.price.replace(/[\s₽]/g, ""))
    // );
    // const [available, setAvailable] = useState(user.available);
    // const [isTrend, setIsTrend] = useState(user.istrend);
    // const [isAdvice, setIsAdvice] = useState(user.isadvice);
    // const [img, setImg] = useState(user.img);

    // const [category, setCategory] = useState(user.id);
    // useEffect(() => {
    //     showMeData(cats);
    // }, [cats]);

    // function showMeData(data) {
    //     const mySet = new Set();
    //     mySet.add("Выберите категорию");
    //     data.map((item) => {
    //         mySet.add(item.label);
    //         return null;
    //     });
    //     setCategories(Array.from(mySet));
    // }
    }

    const op_role = ["ADMIN", "PROF", "NOPROF", "USER"]
    return (
            <div className="form__add">
                <form onSubmit={handleSubmit}>
                
                <label htmlFor="id">Ид:</label>
                    <input
                        type="text"
                        id="id"
                        value={id}
                    />

                <label htmlFor="last_name">Фамилия:</label>
                    <input
                        type="text"
                        id="last_name"
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                        required
                    />
                    <label htmlFor="first_name">Имя:</label>
                    <input
                        type="text"
                        id="first_name"
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                        required
                    />
                    <label htmlFor="nickname">Логин:</label>
                    <input
                        type="text"
                        id="nickname"
                        value={nickname}
                        onChange={(e) => setnickname(e.target.value)}
                        required
                    />

                    <label htmlFor="email">email:</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        required
                    />
                    <label htmlFor="role">role:</label>
                    
                    
                    <select
                        name="role"
                        id="role"
                        value={role}
                        onChange={(e) => setrole(e.target.value)}
                    >


                    {op_role.map((item) => (
                        <option value={item} key={item}>
                            {item}
                        </option>
                    ))}
                    </select>






                    <div className="add-buttons">
                        <button type="submit">Изменить</button>
                        {/* <button type="reset">Очистить форму</button> */}
                    </div>


                </form>
            </div>
    );
}
export default ChangeUser;
