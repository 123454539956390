import "./Footer.scss";
function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="footer__row">
                    <ul className="footer__links">
                        <li className="footer__link">ТИУ ©2024 ВШЦТ</li>
                        {/* <li className="footer__link">Link Two</li> */}
                    </ul>
                </div>
            </footer>
        </>
    );
}
export default Footer;
