function General() {
    return (
        <div className="home fc">
            <div className="home__body fc__body">
            <div className="home__title">СкилЛаб</div>
                <div className="home__subtitle">Пройди тестирование по профориентации</div>
            </div>
        </div>
    );
}
export default General;
