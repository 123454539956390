// RezBlockPage.js
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectIsAuth } from "../redux/slices/userSlice";
import UnAuth from "../components/UI/UnAuth";
import "./Auth.scss";
import SummGroupGroup from "./SummGroupGroup";
import SummGroupGroupMax from "./SummGroupGroupMax";
import SummText from "./SummText";
import SummGroup from "./SummGroup";
import SummGroupXY from "./SummGroupXY";
import { useDispatch } from "react-redux";
import { selectTypeRez_, getTypeRez_ , saveDocumentToServer} from "../redux/slices/testSlice";
import { useParams } from 'react-router-dom';








// const transporter = nodemailer.createTransport({
//   service: 'gmail',
//   auth: {
//     user: 'skillbox172@gmail.com',
//     pass: 'ewmm cots aznp wxkm'
//   }
// })

const RezBlockPage = () => {
  const isAuth = useSelector(selectIsAuth);
  const { id_block, id_user } = useParams();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  
  
  const TypeRez_ = useSelector(selectTypeRez_);

  // useEffect(() => {
  //   if (!isLoaded) {
  //     dispatch(getTypeRez_(id_block_id_user));
  //     setIsLoaded(true);

  //   }
  // }, [dispatch, id_block,id_user, isLoaded]);



  const  id_block_id_user = {
    id_block: id_block,
    id_user: id_user, 
  }

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getTypeRez_(id_block_id_user));
      setIsLoaded(true);

    }
  }, [dispatch, id_block, isLoaded]);



  {
    return (
    <>
    <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js"></script>
    <div className="fc fc-auth">
    {isAuth ? (
    <>
    <div className="wrapper">
    <div className="cabinet">
    {TypeRez_.length > 0 && (
    <div key={TypeRez_[0].id_test}>
    <h2 style={{ fontSize: "28px" }}>Блок: {TypeRez_[0].block_name}</h2>
    </div>
    )}
    
{/* 
    <div>
    
  
  
  {TypeRez_ && TypeRez_.length > 0 && TypeRez_[0].userinfo && TypeRez_[0].userinfo.length > 0 ? TypeRez_[0].userinfo[0].email : 'Поле email не найдено'}
    </div> */}

    <div>
    
  
  
    {/* {console.log(TypeRez_)} */}
    
         </div>

                <div>
                  {TypeRez_.map((item) => (
                    <div key={item.id_test}>
                      <h2 style={{ fontSize: "24px" }}>Тест: {item.test_name}</h2>
                      {
                        item.text_type_rez_ === "summ_group_group" ? <SummGroupGroup item={item} id_user={id_user} /> 
                          :item.text_type_rez_ === "summ_group_group_max" ? <SummGroupGroupMax item={item} id_user={id_user} />
                            :item.text_type_rez_ === "summ_text" ? <SummText item={item} id_user={id_user} />
                              :item.text_type_rez_ === "summ_group" ? <SummGroup item={item} id_user={id_user} />
                                :item.text_type_rez_ === "summ_group_xy" ? <SummGroupXY item={item} id_user={id_user} title_type_rez_={item.title_type_rez_}  />
                                : ""
                          
                      }
                    </div>
                  ))}
                </div>
              </div>
    
      
                <center><button className="linkresults" onClick={handleSubmitResults}>Отправить</button></center>
           

            </div>
          </>
        ) : (
          <UnAuth location="Личный кабинет" />
        )}
      </div>
    </>
    );
    }

    function handleSubmitResults() {
      const wrapperElement = document.querySelector('.cabinet');
      const html = wrapperElement.outerHTML;
    
      // Конвертируем HTML в DOC
      const convertHTMLtoDoc = (html) => {
        // Создаем заголовки DOC-файла
        const docHeader = `
          <html>
            <head>
              <meta charset="UTF-8">
              <title>Документ</title>
            </head>
            <body>
        `;
        const docFooter = `
            </body>
          </html>
        `;
    
        // Объединяем HTML-код с заголовками
        const docContent = docHeader + html + docFooter;
    
        // Создаем Blob с DOC-файлом
        const docBlob = new Blob([docContent], { type: 'application/msword' });
        // const url = URL.createObjectURL(docBlob); для скачивания файла себе
        const docFileData = new Uint8Array(docContent);


    
        // // Создаем ссылку для скачивания
         const link = document.createElement('a');
        // link.href = url; для скачивания файла себе
        // link.download = 'document.doc'; для скачивания файла себе
        // link.click(); для скачивания файла себе
    
        // // Освобождаем URL-объект
        // URL.revokeObjectURL(url); 
    
        // Отправляем файл по электронной почте

      
       // console.log("0===",docContent);
       // console.log("1===",docFileData);
        sendDocByEmail(docContent,TypeRez_ && TypeRez_.length > 0 && TypeRez_[0].userinfo && TypeRez_[0].userinfo.length > 0 ? TypeRez_[0].userinfo[0].email : 'skillbox172@gmail.com',TypeRez_[0].block_name);
        
        window.location.href = '/cabinet';
      };
    
      convertHTMLtoDoc(html);
    } 
    
    function sendDocByEmail(saveDocuments,saveDocumentemail,bloknam) {
      
      const saveDocument = {saveDocuments: saveDocuments, saveDocumentemail: saveDocumentemail, bloknam: bloknam};

     // console.log("2===",saveDocument.saveDocuments);

      dispatch(saveDocumentToServer(saveDocument));
      //console.log(email);
    }

    // function handleSubmitResults() {
    //   const wrapperElement = document.querySelector('.cabinet');
    //   const html = wrapperElement.outerHTML;
    
    //   // Конвертируем HTML в DOC
    //   const convertHTMLtoDoc = (html) => {
    //     // Создаем заголовки DOC-файла
    //     const docHeader = `
    //       <html>
    //         <head>
    //           <meta charset="UTF-8">
    //           <title>Документ</title>
    //         </head>
    //         <body>
    //     `;
    //     const docFooter = `
    //         </body>
    //       </html>
    //     `;
    
    //     // Объединяем HTML-код с заголовками
    //     const docContent = docHeader + html + docFooter;
    
    //     // Создаем Blob с DOC-файлом
    //     const docBlob = new Blob([docContent], { type: 'application/msword' });
    //     const url = URL.createObjectURL(docBlob);
    
    //     // Создаем ссылку для скачивания
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'document.doc';
    //     link.click();
    
    //     // Освобождаем URL-объект
    //     URL.revokeObjectURL(url);
    //   };
    
    //   convertHTMLtoDoc(html);
    // }


    // function handleSubmitResults() {
    //   const wrapperElement = document.querySelector('.wrapper');
    //   const html = wrapperElement.outerHTML;
    
    //   const blob = new Blob([html], { type: 'text/html;charset=utf-8' });
    //   const url = URL.createObjectURL(blob);
    
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = 'document.html';
    //   link.click();
    
    //   URL.revokeObjectURL(url);
    // }

    // function handleSubmitResults() {
    //   const content = document.querySelector('.cabinet').innerHTML;
      
    //   const modifiedContent = content.replace(/style="/g, 'customStyle="');
    
    //   const doc = new docx.Document({
    //     creator: 'Ваше Имя',
    //     title: 'Документ',
    //     sections: [
    //       {
    //         properties: {},
    //         children: [new docx.Paragraph(modifiedContent)],
    //       },
    //     ],
    //     styles: {
    //       paragraphStyles: [
    //         {
    //           id: 'customStyle',
    //           basedOn: 'Normal',
    //           next: 'Normal',
    //           run: {
    //             size: 24,
    //             bold: true,
    //           },
    //         },
    //       ],
    //     },
    //   });
    
    //   docx.Packer.toBase64String(doc).then((base64) => {
    //     const binaryData = atob(base64);
    //     const arrayBuffer = new ArrayBuffer(binaryData.length);
    //     const uint8Array = new Uint8Array(arrayBuffer);
    
    //     for (let i = 0; i < binaryData.length; i++) {
    //       uint8Array[i] = binaryData.charCodeAt(i);
    //     }
    
    //     const blob = new Blob([uint8Array], {
    //       type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //     });
    
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'document.docx';
    //     link.click();
    //   });
    // }

};

export default RezBlockPage;
