import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    faVk,
    faInstagram,
    faTelegram,
    faWhatsapp,
    faViber,
} from "@fortawesome/free-brands-svg-icons";
import "./Contact.scss";


function Contacts() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState(0);
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
   
    return (
        <div className="home fc">
        <div className="wrapper">
            </div>
            </div>
    );
}
export default Contacts;
