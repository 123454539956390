// RezTest2.js
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupGroup, selectGroupGroup} from "../redux/slices/testSlice";
import { API_URL } from "../config";


import axios from "axios";

const SummGroupGroupMax = ({ item, id_user }) => {


  const [SumText, setSumText] = useState([]);
  const [GroupGroup, setGroupGroup] = useState([]);

  const id_test_typesumm_id_user = useMemo(() => ({
    id_test: item.id_test,
    typesumm: item.typesumm,
    id_user: id_user,
  }), [item.id_test, item.typesumm, id_user]);

  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios.get(`${API_URL}/test/getSummText?id_test=${item.id_test}&id_user=${id_user}`)
      .then((response) => {


        // console.log(response.data.data);
        const SumText = response.data.data;


        const groupedData = response.data.data[0].data2.reduce((acc, group) => {
          if (!acc[group.title_group]) {
            acc[group.title_group] = [];
          }
          acc[group.title_group].push(group);
          return acc;
        }, {});

       setGroupGroup(Object.values(groupedData));
       

        // console.log(SumText);
        setSumText(SumText);
        // console.log(SumText);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [item.id_test, item.typesumm, id_user]);



  const titleTypeRez = JSON.parse(item.title_type_rez_);

  return (
    <div key={item.id_test}>

      {/* {console.log(SumText)} */}
      {/* наибольшая схожесть */}
      <h2 style={{ fontSize: "19px" }}>{titleTypeRez.max_title}</h2>
      {SumText
  .map((group, index) => (
    <div style={{ fontSize: "19px" }} key={index}>
      <b>{group.title_text}</b>
      <div style={{ fontSize: "17px" }}>
      {group.text_text}
      <br />
    </div>
    </div>
  ))}




<br />
      <br />


      {/* вывод с группировкой */}

      <h2 style={{ fontSize: "19px" }}>{titleTypeRez.main_title}</h2>
      {GroupGroup.map((groupedItems, index) => (
        <div key={index}>
          <b style={{ fontSize: "19px" }}>{groupedItems[0].title_group}</b>
          {groupedItems.map((group, groupIndex) => (
            <li style={{ fontSize: "19px" }}key={groupIndex}>
              {group.title_value}
            </li>
          ))}
        </div>
      ))}


<br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};



export default SummGroupGroupMax;
