

import { useDispatch, useSelector } from "react-redux";
import {
    changeUser,
    fetchAllUsers,
    deleteUser
} from "../../redux/slices/usersSlice";

import ChangeUser from "./ChangeUser";

import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";


import { faTrash, faPenToSquare, faVcard } from "@fortawesome/free-solid-svg-icons";


import MyDialog from "../UI/MyDialog";
import PhotoLoadingHandler from "../UI/PhotoLoadingHandler";
import { useState, useEffect } from "react";


import "./Item.scss";

function User(props) {
    
    // console.log(props)
    const [showEditWindow, setShowEditWindow] = useState(false);

    function handleDeleteUser() {
        let result = window.confirm("Вы действительно хотите удалить пользователя ?");
        if (result) {
            dispatch(deleteUser(id));
        } else {
            return;
        }
    }


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);


    
    // const {id, last_name, first_name, nickname, email, role, user_img} = allUsers;
    
   
    
    const { id, last_name, first_name, user_img, role} = props.data;
    return (
        // (item={allUsers}
        <div className="item">
            <div className="item__row">
                {/* <div className="item__img">
                        <PhotoLoadingHandler img={user_img} />
                </div> */}
                {/* <div className="item__body"> */}
                <div className="item__title">ФИО: {first_name}&nbsp; {last_name}</div>
            
                <div className="item__values">Роль: {role} </div>
                        <div className="item__buttons">
                        <FontAwesomeIcon
                                icon={faVcard}
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowEditWindow(true)}
                                // onClick={alert(this.parent)}
                            />&nbsp;
                                 <FontAwesomeIcon
                                icon={faTrash}
                                className="delete-icon"
                                onClick={handleDeleteUser}
                                style={{ cursor: "pointer" }}
                            />          
                        </div>
                    {/* </div> */}
                </div>
                <MyDialog
                    visible={showEditWindow}
                    setVisible={setShowEditWindow}
                >
                    <ChangeUser
                        setVisible={setShowEditWindow}
                        user = {props}
                    />
                </MyDialog>
       
                </div> 

    );       

}
export default User;
