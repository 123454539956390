import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setError } from "./errorSlice";
import { API_URL } from "../../config";
import { setSuccess } from "./successSlice";
const initialState = {
    getStaticLoading: false,
};


export const getStatic = createAsyncThunk(
    "admin/getStatic",
    async (thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/admin/getStatic`);
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(setError(error.response.data.message));
            throw error;
        }
    }
);

const adminPanelSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        const handleApiCall = (state, action) => {
            const typeOfFetchingData = action.type.split("/")[1];
            switch (typeOfFetchingData) { 
                case "getStatic":
                    state.getStaticLoading = true;
                    break;
                default:
                    break;
            }
        };

        const handleApiSuccess = (state, action) => {
            if (!action.payload) {
                return initialState;
            }
            const typeOfFetchingData = action.type.split("/")[1];
            switch (typeOfFetchingData) {
                case "getStatic":
                    state.getStaticLoading = false;
                    break;
                default:
                    break;
            }
        };

        const handleApiError = (state, action) => {
            const typeOfFetchingData = action.type.split("/")[1];

            switch (typeOfFetchingData) {
                case "getStatic":
                    state.getStaticLoading = false;
                    break;
                default:
                    break;
            }
        };

        builder
            .addCase(getStatic.pending, handleApiCall)
            .addCase(getStatic.fulfilled, handleApiSuccess)
            .addCase(getStatic.rejected, handleApiError)
    },
});
export const selectGetStaticLoading = (state) => state.admin.getStaticLoading;

export default adminPanelSlice.reducer;
