import { Routes, Route } from "react-router-dom";
import Contacts from "../../pages/Contacts";
import About from "../../pages/About";
import Home from "../../pages/Home";
import Test from "../../pages/Test";
import Cabinet from "../../pages/Cabinet";
import Auth from "../../pages/Auth";
import AdminPanel from "../../pages/AdminPanel";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsAuth } from "../../redux/slices/userSlice";
import Page404 from "../UI/Page404";
import ResultsPage from '../../redux/slices/ResultsPage';

import RezBlockPage from '../../pages/RezBlockPage';

function Router() {
    const isAdmin = useSelector(selectIsAdmin);
    const isAuth = useSelector(selectIsAuth);
    return (
        <div>

           
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="test/:id_test" element={<Test/>} />
                <Route path="cabinet" element={<Cabinet />} />
                <Route path="auth" element={<Auth />} />
                <Route path="/reztest/:id_block/:id_user" element={<RezBlockPage />} />
                
                {isAdmin && (
                    <>
                        <Route path="admin" element={<AdminPanel />} />
                    </>
                )}
                 {isAuth && (
                    <>
                       
                    </>
                )}
                <Route path="*" element={<Page404 />} />
            </Routes>
        </div>
    );
}
export default Router;
