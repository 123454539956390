import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import General from "../components/Home/General";
import "./Pages.scss";
import { selectUserId } from "../redux/slices/userSlice";
function Home() {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    return (
        <>
            <General />
        </>
    );
}
export default Home;
