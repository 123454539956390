// RezTest2.js
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupGroup, selectGroupGroup} from "../redux/slices/testSlice";
import { API_URL } from "../config";


import axios from "axios";

const SummGroup = ({ item, id_user }) => {

  const [Group, setGroup] = useState([]);

  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // console.log(item.id_test, id_user)
    axios.get(`${API_URL}/test/getSummGroup?id_test=${item.id_test}&id_user=${id_user}`)
      .then((response) => {

        // console.log(response.data.data);

        // const groupedData = response.data.data.reduce((acc, group) => {
        //   if (!acc[group.text_summ_group]) {
        //     acc[group.text_summ_group] = [];
        //   }
        //   acc[group.text_summ_group].push(group);
        //   return acc;
        // }, {});
        // setGroupGroup(Object.values(groupedData));


        setGroup(response.data.data);


        // console.log(groupedData);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [item.id_test, id_user]);


  const titleTypeRez = JSON.parse(item.title_type_rez_);
  // console.log(Group);
  // const titleTypeRez = { main_title: item.title_type_rez_ };

  return (
    <div key={item.id_test}>
      {/* вывод с группировкой */}
      <h2 style={{ fontSize: "19px" }}>{titleTypeRez.main_title}</h2>
      {Group.map((group, index) => (
        <div key={index}>
          <p style={{ fontSize: "19px" }}>{group.text_answer_group}
            <li style={{ fontSize: "19px" }}>
              <b>{group.title_summ_group}</b> ({group.summ_value_answer}/{group.max_summ}){group.text_summ_group ? " - " + group.text_summ_group : group.text_summ_group}
              </li>
          {/* ))} */}
          </p>
          <br/>
        </div>
      ))}

<br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};



export default SummGroup;
