import "./MyCabinet.scss";
import UserInfo from "./UserInfo";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createUser,
} from "../../redux/slices/usersSlice";
import { API_URL } from '../../config.js';

import { useNavigate } from 'react-router-dom';


import {
  selectUserId,
  selectIsProf,
  selectIsUser
} from "../../redux/slices/userSlice";

import { faFolder, faFolderOpen } from "@fortawesome/free-solid-svg-icons";

import {
  selectBlock,
  selectTestBlock,
  selectUserTest,
  selectUserProf,
  selectUserBlock,

  getUserTest,
  getBlock,
  getTestBlock,
  getUserProf,
  getUserBlock,

  
  AnswerUser,
  saveUserTest,

} from "../../redux/slices/testSlice";


import { useSelector } from "react-redux";

import { useEffect } from "react";


import axios from 'axios';




function MyCabinet() {


  const navigate = useNavigate();

       

    const [isCreate, setIsCreate] = useState(false);

    const [last_name, setlast_name] = useState("");
    const [first_name, setfirst_name] = useState("");
    const [nickname, setnickname] = useState("");
    const [email, setemail] = useState("");
    const [role, setrole] = useState("");
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    

    // console.log(userId)
    function handleSubmit(e) {
      e.preventDefault();
      // if (category === "Выберите роль") {
      //     alert("Выберите роль");
      // } else {
      // }
              

      const createdUser = {
        last_name,
        first_name,
        email,
        userId
      };

      // dispatch = useDispatch();
      dispatch(createUser(createdUser));

      setlast_name("");
      setfirst_name("");
      setnickname("");
      setemail("");
      setrole("");
      setIsCreate(true);
      window.location.href = '/cabinet';

 }


 

//начать заново, прйти тест
 const handleOpenTest = (id_test, status) => {

  const id_test_id_user_status = {id_test: id_test, id_user: userId, status: status}

  if (status === 'Выдан') {
    dispatch(AnswerUser(id_test_id_user_status))
    id_test_id_user_status.status = 'Начат'
    dispatch(saveUserTest(id_test_id_user_status))
    navigate('/test/' + id_test);
  }

  // не стириается
  else if (status === 'Пройден') {
    if (window.confirm('Вы уверены, что хотите пройти тест заново? Все предыдущие ответы будут удалены.')) { 
      dispatch(AnswerUser(id_test_id_user_status))
        .then (() => {
          id_test_id_user_status.status = 'Начат'
          dispatch(saveUserTest(id_test_id_user_status))
        })
          .then (() => {
            navigate('/test/' + id_test);
          })
      
      
    }
  }

  else if (status === 'Начат') {
    dispatch(AnswerUser(id_test_id_user_status))
    navigate('/test/' + id_test);
  }
};


const handleOpenResults =  async (id_block, id_user) => {


  try {
    // Проверяем, что id_user не равен undefined
    if (id_user !== undefined) {
      // Отправляем запрос на сервер для получения данных из таблицы answer_user

      const response = await axios.get(`${API_URL}/rezz/getRezz?id_block=${id_block}&id_user=${id_user}`);
      // Получаем данные из ответа
      const { data } = response.data;

      // Переходим на страницу с результатами, передавая данные в качестве параметров
      navigate(`/reztest/${id_block}/${id_user}`, { state: { results: data } });

    } else {
      console.error('id_user не передан');
      // Обработка ошибки, например, показ сообщения пользователю
    }
  } catch (error) {
    console.error('Ошибка при получении результатов:', error);
    // Обработка ошибки, например, показ сообщения пользователю
  }
};




useEffect(() => {
  dispatch(getBlock(userId));
  dispatch(getUserTest());
  dispatch(getTestBlock());
  dispatch(getUserProf(userId));
  dispatch(getUserBlock());
  setIsCreate(false);
}, [isCreate, dispatch, ]); 





    const isProf = useSelector(selectIsProf);
    const isUser = useSelector(selectIsUser);
  
    const Block = useSelector(selectBlock);
    const TestBlock = useSelector(selectTestBlock);
    const UserTest = useSelector(selectUserTest);
    
    const UserProf = useSelector(selectUserProf);
    const UserBlock = useSelector(selectUserBlock);
    // const UserTest = useSelector(selectUserBlock);






    

    const DropTest = ({ test, showRestartButton }) => {
      return (
        <li style={{ cursor: 'pointer' }}>
          {test.name} - {test.status}
          {test.status === 'Пройден' && showRestartButton && (
            <></>
            // <button className="linktestre" onClick={() => handleOpenTest(test.id_test, test.status)}>
            //   Пройти заново
            // </button>
          )}
        </li>
      );
    };


    

    
    const DropBlock = ({ block, tests}) => {

      const [isOpen, setIsOpen] = useState(false);
      const [allTestsPassed, setAllTestsPassed] = useState(true);
    
      const handleClick = () => {
        setIsOpen(!isOpen);
      };





      // Сбросить тесты для конкретного блока и пользователя
      const handleResetTestsForBlock = () => {
        if (window.confirm('Вы действительно хотите сбросить все тесты для этого блока на статус "Выдан"?')) {
          const testToReset = tests.filter(
            (test) => test.id_block === block.id_block && test.id_user === block.id_user
          );
          testToReset.forEach((test) => {
            const id_test_id_user_status = {
              id_test: test.id_test,
              id_user: test.id_user,
              status: 'Выдан'
            };
            dispatch(AnswerUser(id_test_id_user_status));
            dispatch(saveUserTest(id_test_id_user_status));
          });
        }
        //window.location.href = '/cabinet';
      };

      const handleConfirmReset = () => {
        if (window.confirm('Вы действительно хотите сбросить прохождение тестов?')) {
          handleResetTestsForBlock();
        }
      };
    
      useEffect(() => {
        const filteredTests = tests.filter(
          (test) => block.id_user === test.id_user && block.id_block === test.id_block
        );
    
        const allPassed = filteredTests.every((test) => test.status === 'Пройден');
        setAllTestsPassed(allPassed);
      }, [block.id_user, block.id_block, tests]);


    
      return (
        <div key={block.id_block}>
          <div onClick={handleClick} className="head_block">
            {block.name} {isOpen ? (
              <FontAwesomeIcon icon={faFolderOpen} style={{ cursor: 'pointer' }} />
            ) : (
              <FontAwesomeIcon icon={faFolder} style={{ cursor: 'pointer' }} />
            )}
          </div>
          {isOpen && (
            <div className="content_block">
              {tests
                .filter((test) => test.id_block === block.id_block && test.id_user === block.id_user)
                .map((test) => (
                  <DropTest key={test.id_test} test={test} showRestartButton={!allTestsPassed} />
                ))}
              {allTestsPassed && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <button className="linkresults" onClick={() => handleOpenResults(block.id_block,block.id_user)}>
                    Результаты
                  </button>
                  <button className="linkresults" onClick={handleConfirmReset}>
                    Сбросить
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      );
    };
    
  
      const DropUser = ({ user, blocks, tests,  }) => {
        const [isOpen, setIsOpen] = useState(false);
        const handleClick = () => {
          setIsOpen(!isOpen);
        };
        return (
          <div>
            <div onClick={handleClick} className="head_block">
            {user.first_name} {user.last_name} {isOpen ? (<FontAwesomeIcon icon={faFolderOpen} style={{ cursor: "pointer"}} />) : (<FontAwesomeIcon icon={faFolder} style={{ cursor: "pointer" }} />)}
            </div>
            {isOpen && (<div className="content_block"> 
            {blocks
                  .filter((block) => block.id_user === user.id_user && block.id_prof === userId)
                  .map((block) => (
                    <DropBlock key={block.id_test} block={block} tests={tests} />
                  ))}
              </div>
            )}
          </div>
        );
      };



      // const DropUserBlock = ({ block, tests,  }) => {
      //   const [isOpen, setIsOpen] = useState(false);
      //   const handleClick = () => {
      //     setIsOpen(!isOpen);
      //   };
      //   return (
      //     <div>
      //       <div onClick={handleClick} className="head_block">
      //       {block.name} {isOpen ? (<FontAwesomeIcon icon={faFolderOpen} style={{ cursor: "pointer"}} />) : (<FontAwesomeIcon icon={faFolder} style={{ cursor: "pointer" }} />)}
      //       </div>
      //       {isOpen && (<div className="content_block"> 
      //           {tests
      //             .filter((test) => block.id_user === test.id_user && block.id_block === test.id_block)
      //             .map((test) => (
      //               <DropUserBlockTest key={test.id_test} test={test} />
      //             ))}
      //         </div>
      //       )}
      //     </div>
      //   );
      // };


    
   

      const DropUserBlock = ({ block, tests }) => {
        
      
        const handleGoToHomePage = () => {
          navigate('/');
        };
      
        const [isOpen, setIsOpen] = useState(false);
        const [allTestsPassed, setAllTestsPassed] = useState(true);
      
        const handleClick = () => {
          setIsOpen(!isOpen);
        };
      
        useEffect(() => {
          const filteredTests = tests.filter(
            (test) => block.id_user === test.id_user && block.id_block === test.id_block
          );
      
          const allPassed = filteredTests.every((test) => test.status === 'Пройден');
          setAllTestsPassed(allPassed);
        }, [block.id_user, block.id_block, tests]);
      
        return (
          <div>
            <div onClick={handleClick} className="head_block">
              {block.name} {isOpen ? (
                <FontAwesomeIcon icon={faFolderOpen} style={{ cursor: 'pointer' }} />
              ) : (
                <FontAwesomeIcon icon={faFolder} style={{ cursor: 'pointer' }} />
              )}
            </div>
            {isOpen && (
              <div className="content_block">
                {tests
                  .filter((test) => block.id_user === test.id_user && block.id_block === test.id_block)
                  .map((test) => (
                    <DropUserBlockTest key={test.id_test} test={test} showRestartButton={!allTestsPassed} />
                  ))}
                {allTestsPassed && (
                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                    <button className="linkresults" onClick={() => handleOpenResults(block.id_block,block.id_user)}>
                      Результаты
                    </button>
                    <button className="linkresults" onClick={handleGoToHomePage}>
                      На главную
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      };
      
      const DropUserBlockTest = ({ test, showRestartButton }) => {
        return (
          <li style={{ cursor: 'pointer' }}>
            {test.name} &nbsp;
            {test.status === 'Выдан' && <button className="linktestgo" onClick={() => handleOpenTest(test.id_test, test.status)}>Пройти тест</button>}
            {test.status === 'Пройден' && showRestartButton && <button className="linktestre" onClick={() => handleOpenTest(test.id_test, test.status)}>Пройти заново</button>}
            {test.status === 'Начат' && <button className="linktestfull" onClick={() => handleOpenTest(test.id_test, test.status)}>Продолжить прохождение</button>}
          </li>
        );
      };

      return (
        <>
        {/* (test.id_test)
        <button onClick={() => handleOpenTest(test.id_test)}>Пройти тест</button> */}
            <div className="cabinet">
            <h2>Личный кабинет</h2>

            
                <UserInfo />
                {/* <div className="panel__buttons">
                    <button onClick={() => setShowAddUser(true)}>
                        Добавить юзера на тесты
                    </button>
                </div> */}


{isProf ? (

<>
<br></br>
<br></br>
<h2>Приглашение на обучение</h2>
<div className="form__add">
<form onSubmit={handleSubmit}>
<label htmlFor="first_name">Имя:</label>
    <input
        type="text"
        id="first_name"
        value={first_name}
        onChange={(e) => setfirst_name(e.target.value)}
        required
    />
    <label htmlFor="last_name">Фамилия:</label>
    <input
        type="text"
        id="last_name"
        value={last_name}
        onChange={(e) => setlast_name(e.target.value)}
        required
    />
    
    <label htmlFor="email">email:</label>
    <input
        type="text"
        id="email"
        value={email}
        onChange={(e) => setemail(e.target.value)}
        required
    />



    <div className="add-buttons">
        <button type="submit">Добавить</button>
        {/* <button type="reset">Очистить форму</button> */}
    </div>
</form>
</div>
<br></br>
<br></br>

      


<h2>Результаты тестов</h2>




<div>
{UserProf.map((user) => (
<DropUser key={user.id_user} user={user} blocks={UserBlock} tests={UserTest} />
))}
</div>

</>


) : ""}




{isUser ? 

<>
<h2>Тесты</h2>
<div> 
    {UserBlock
    .filter((block) => block.id_user === userId)
    .map((block) => (
      <DropUserBlock key={block.id_user} block={block} tests={UserTest} />
    ))}
</div>
</>


 : "" }


            </div>
    



        
    
            {/* <div>
          {UserProf.map((user) => (
            <DropBlock key={user.id_user} block={block} tests={TestBlock} users={UserTest} />
          ))}
        </div> */}

        
        </>
    


   


);






}
export default MyCabinet;
