import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import errorSlice from "./slices/errorSlice";
import usersSlice from "./slices/usersSlice";
import adminPanelSlice from "./slices/adminPanelSlice";
import successSlice from "./slices/successSlice";
import testSlice from "./slices/testSlice";
const store = configureStore({
    reducer: {
        user: userSlice,
        error: errorSlice,
        success: successSlice,
        users: usersSlice,
        admin: adminPanelSlice,
        test: testSlice,
    },
});
export default store;
